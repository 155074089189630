<template>
  <div>
    <h2 id="hintergrund">Hintergrundwissen</h2>
    <p>
      Im Laufe der ersten Schuljahre sollen Lernende
      <strong>ein tragfähiges Stellenwertverständnis</strong> aufbauen, das im
      Laufe der Zeit immer weiter ausdifferenziert wird. Ein fehlendes oder
      unzureichend ausgeprägtes Stellenwertverständnis erschwert vielen Kindern
      das Mathematiklernen (z.B. Moser Opitz & Schmassmann, 2007; Wartha &
      Schulz, 2014). Es konnte gezeigt werden, dass Kinder mit einem schlechter
      ausgebildeten Stellenwertverständnis mehr Fehler machen und ein kleineres
      Repertoire an Rechenstrategien nutzen, als Kinder, die im Vergleich
      bessere Kenntnisse des Stellenwertsystems haben (Carpenter, Franke,
      Jacobs, Fennema & Empson, 1998). So greifen Kinder mit unzureichendem
      Stellenwertverständnis für die Addition und Subtraktion mehrstelliger
      Zahlen beispielsweise häufig auf den schriftlichen Algorithmus zurück oder
      rechnen ziffernweise (Carpenter et al., 1998; Thompson & Bramald, 2002).
      Zudem wurde in verschiedenen Studien ein sicheres Stellenwertverständnis
      als Prädikator für die weiteren Mathematikleistungen sowohl in der
      Primarstufe (Möller, Pixner, Zuber, Kaufmann & Nürk, 2011) als auch für
      die weiterführende Schule (Moser Opitz, 2007) nachgewiesen.
    </p>
    <p>
      Beim <i>Stellenwertverständnis</i> geht es nicht nur um die Zahlen selbst,
      sondern auch um ihre Schreibfiguren (Krauthausen & Scherer, 2007, S. 17;
      Schipper, 2009, S. 119). In den ersten sechs Schuljahren sollen die
      Lernenden verstehen, wie Zahlen im Zehnersystem dargestellt werden. Das
      Zehnersystem zeichnet sich dadurch aus, dass es allein mit 10 Ziffern von
      0 bis 9 möglich ist, Zahlen in beliebiger Größe darzustellen und mit ihnen
      zu operieren. Drei mathematische Ideen sind für das Verständnis des
      Stellenwertsystems von zentraler Bedeutung (Wartha & Schulz, 2014, S.
      48ff.):

      <ol>
        <li><i>Bündeln und Entbündeln</i></li>
        <li><i>Stellenwert und Zahlenwert</i></li>
        <li><i>Darstellungen vernetzen</i></li>
      </ol>
      Im Folgenden jede dieser drei Ideen näher betrachtet.
    </p>

    <h3>Bündeln und Entbündeln</h3>
    <p>
      Die erste mathematische Idee, die von zentraler Bedeutung für das
      Verständnis von Stellenwertsystemen ist, ist das Bündeln und Entbündeln.
      Zunächst wird im Folgenden näher beleuchtet, was das Bündelungsprinzip aus
      mathematischer Perspektive ausmacht und welche Vorteile es bietet. Dies
      stellt das grundlegende Wissen dar, das Du als Lehrkraft brauchst, um
      Kinder in ihrem Lernprozess optimal unterstützen zu können.
    </p>
    <h4>Was solltest Du zu Bündeln und Entbündeln wissen?</h4>
    <p>
      Zahlen werden heute im Stellenwertsystem zur Basis 10 dargestellt – das
      war nicht immer so, denke z. B. an die römische Zahlschreibweise.
      Stellenwertsystemen liegt dabei grundsätzlich das Prinzip der
      fortgesetzten Bündelung zugrunde (vgl. Padberg & Benz 2011). Dieses
      besagt, dass Objekte so lange zu Zehnerbündeln und damit Einheiten
      nächsthöherer Ordnung zusammengefasst werden, bis kein neues Zehnerbündel
      mehr voll wird. In der Grundschule werden als Material häufig Einerwürfel,
      Zehnerstangen, Hunderterplatten etc. verwendet. Nach dem Prinzip der
      fortgesetzten Bündelung müssen zehn Einerwürfel demnach in den
      nächsthöheren Stellenwert, also zu einer Zehnerstange, gebündelt werden.
      Außerdem werden zehn Bündel des höheren Stellenwerts wiederum ebenfalls zu
      neuen Bündeln ‚höherer Ordnung’ zusammengefasst. Jede Menge kann hierdurch
      durch fortgesetztes Bündeln eindeutig und schnell erkennbar dargestellt
      werden (vgl. Padberg & Benz 2011). Dies lässt sich am folgenden Beispiel
      erkennen (in Anlehnung an Schulz, 2015):
    </p>

    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              <div class="imageTableImages" style="width: 150px">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/swve/swve_einer.png')"
                  parentId="swvi"
                  elementId="swve_einer.png"
                ></AppExpandableImage>
              </div>
              <i>Abb. Einerwürfel</i>
            </td>
            <td>
              Bei dieser Anzahl von Einerwürfeln ist auf einen Blick nicht
              erkennbar, um wie viele Würfel es sich handelt.
            </td>
          </tr>
          <tr>
            <td>
              <div class="imageTableImages" style="width: 200px">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/swve/swve_zehner.png')"
                  parentId="swvi"
                  elementId="swve_zehner.png"
                ></AppExpandableImage>
              </div>
              <i>Abb. Bündeln zu Zehnerstangen</i>
            </td>
            <td>
              Ein erster Schritt zu mehr Übersichtlichkeit kann die
              Strukturierung in Zehnerbündel (Umtausch in Zehnerstangen) sein.
            </td>
          </tr>
          <tr>
            <td>
              <div class="imageTableImages" style="width: 200px">
                <AppExpandableImage
                  contain
                  :src="require('@/assets/swve/swve_voll_buendelung.png')"
                  parentId="swvi"
                  elementId="swve_voll_buendelung.png"
                ></AppExpandableImage>
              </div>
              <i>Abb. Vollständige Bündelung</i>
            </td>
            <td>
              Bündelt man weiter, so ist die Anzahl der Würfel für uns mit einem
              Blick erkennbar.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      Solche Bündelungsaktivitäten lassen sich im Unterricht mit den Kindern
      gemeinsam durchführen. So kann Einsicht in den Sinn des Bündelns erlangt
      und das Prinzip verstehend erarbeitet werden. Ebenso kann erarbeitet
      werden, dass das Prinzip nur einwandfrei funktioniert, wenn immer maximal
      gebündelt wird. Das von der konkreten Anschauung abgelöste Prinzip ist
      auch für das Verständnis von Dezimalzahlen von zentraler Bedeutung. So
      kann man sich vorstellen, dass auch Einer entbündelt werden können,
      wodurch Zehntel und durch weiteres Entbündeln Hundertstel usw. entstehen.
    </p>

    <!-- <p style="text-align: center;"> -->
    <div style="width: 500px; margin: 20px auto 20px;">
      <AppExpandableImage
        contain
        :src="require('@/assets/swve/swve_fort_buendeln.png')"
        parentId="swvi"
        elementId="swve_fort_buendeln.png"
      >
      </AppExpandableImage>
      <i>Abb. fortgesetztes Bündeln und Entbündeln mit Dezimalzahlbereich</i>
    </div>
    <!-- </p> -->

    <p>
      In unserem üblichen dekadischen System sind wir es gewohnt zu bündeln, zu
      entbündeln und können leicht angeben, wie bei einer sukzessiven Erhöhung
      einer Zahl um einen bestimmten Wert die nächste Zahl aussehen muss. Dies
      müssen die Kinder in der Grundschule allerdings erst noch lernen.
    </p>
    <p>
      Um sich noch einmal in die Situation hineinversetzen zu können, wie es ist
      ein Stellenwertsystem noch nicht (vollständig) durchdrungen und
      verinnerlicht zu haben, kannst Du das folgende Video anschauen.
    </p>

    <p>
      Schaue Dir zunächst das Video zum Weiterzählen im Vierersystem an. Hier
      wird sukzessive immer genau ein Plättchen hinzugefügt. In unseren
      Beispielen herrscht Bündelungszwang. Grundsätzlich ist es aber nicht
      falsch, wenn Kinder Anzahlen, die die Bündelungseinheit überschreiten, in
      einer Stellenwerttafel notieren. Erst bei der Übersetzung in die
      Normalschreibweise, also die Darstellung der Zahl mittels Zahlsymbolen,
      muss vollständig gebündelt werden. Dabei besteht dann der Bündelungszwang.
    </p>
    <!-- Video SCHIEBEREGLER 
        https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/1%20Hintergrund/1%20Bündeln%20und%20Entbündeln/4er%20System&openfile=2214952
        ENDE Video SCHIEBEREGLER -->
    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @fullscreenchange="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @ended="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @pause="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @seeking="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @enterpictureinpicture="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')
      "
    >
      <!-- @seeking="videoPlayerEvent($event, '4er-System um 1 weiterzählen_201103_YB.mp4')" -->
      <source
        src="@/assets/swvi/4er-System um 1 weiterzählen_201103_YB.mp4"
        type="video/mp4"
      />
    </video>

    <p>
      Vielleicht hast Du festgestellt, dass es Dir im Vierersystem etwas
      schwerer fällt schnell zu nachzuvollziehen, wie die nächstgrößere Zahl
      aussieht. Um die Aufgabe zu lösen, muss man prüfen, ob durch das
      Hinzufügen eines Plättchens eine Bündelung erforderlich ist oder nicht.
      Auch muss man entscheiden, ob nach einer Bündelung noch Plättchen in der
      Spalte, in der eine Bündelung erforderlich wurde, übrigbleiben. Kindern,
      die noch nicht sicher im Umgang mit dem Zehnersystem sind, können diese
      Entscheidungen beim sukzessiven Erhöhen von Zahlen durch das Hinzufügen
      von jeweils einem Plättchen in einer Stellenwerttafel durchaus
      schwerfallen.
    </p>

    <p>
      Noch schwieriger wird dies, wenn die zu verändernden Zahlen größer sind
      und zudem nicht nur um eins, sondern um einen anderen Wert erhöht wird. Um
      Dir dies auch dies zu vergegenwärtigen, wir Dir im nachfolgenden Video
      gezeigt, wie die Zahl (230)<sub>5</sub> sukzessive immer genau um zwei
      Einer erhöht wird.
    </p>

    <!-- Video SCHIEBEREGLER
https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/1%20Hintergrund/1%20Bündeln%20und%20Entbündeln/5er%20System&openfile=2214955
ENDE Video SCHIEBEREGLER -->
    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @fullscreenchange="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @ended="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @pause="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @seeking="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @enterpictureinpicture="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, '5er-System um 2 weiterzählen_201103_YB.mp4')
      "
    >
      <source
        src="@/assets/swvi/5er-System um 2 weiterzählen_201103_YB.mp4"
        type="video/mp4"
      />
    </video>

    <p>
      Vermutlich ist Dir aufgefallen, dass bei der Betrachtung größerer Zahlen
      und der Veränderung der Zahlen um den Wert zwei gleich mehrere Dinge zu
      beachten sind:
      <ol>
        <li>Ist eine Bündelung erforderlich?</li>
        <li>Verbleiben nach einer Bündelung noch Plättchen in der Spalte, in der eine Bündelung erforderlich wurde?</li>
        <li>Ist in der Spalte, in die Plättchen durch die Bündelung überführt wurden, durch das Hinzukommen der gebündelten Plättchen gegebenenfalls eine weitere Bündelung erforderlich?</li>
      </ol>
      Für Kinder, die im Stellenwertsystem zur Basis 10 noch nicht sicher
      agieren können, stellen diese vielfältigen Anforderungen eine große
      Herausforderung dar, wenn sie dort ähnliche Aufgaben lösen.
    </p>
    <p>
      Abschließend kannst Du nun nochmal den Fall betrachten, dass Zahlen um
      einen Wert, der größer als die Basis ist, verändert werden, sodass dies
      zum Hinzufügen von Plättchen in mehreren Spalten führt. Schaue Dir dafür
      das nachfolgende Video an, in dem im Dreiersystem sukzessive um vier Einer
      weitergezählt wird.
    </p>

    <!-- Video SCHIEBEREGLER 
https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/1%20Hintergrund/1%20Bündeln%20und%20Entbündeln/3er%20System&openfile=2214946
ENDE Video SCHIEBEREGLER -->
    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @fullscreenchange="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @ended="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @pause="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @seeking="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @enterpictureinpicture="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, '3er-System um 4 weiterzählen_201103_YB.mp4')
      "
    >
      <source
        src="@/assets/swvi/3er-System um 4 weiterzählen_201103_YB.mp4"
        type="video/mp4"
      />
    </video>

    <p>
      Ähnlich wie beim Video zum Fünfersystem müssen hier viele Dinge
      berücksichtigt werden. Da hier gleichzeitig in zwei Spalten jeweils ein
      Plättchen hinzugefügt wurde, musstest Du dabei jedoch immer direkt
      mindestens zwei Spalten im Blick behalten.
    </p>

    <p>
      Wenn Kinder Zahlen um einen bestimmten Wert erhöhen sollen und dies durch
      das selbstständige Legen von Plättchen in eine Stellenwerttafel
      visualisieren, müssen sie verstehen, dass eine Erhöhung um eins dem
      Hinzufügen eines Plättchens in der Einerspalte oder eine Erhöhung um vier
      dem Hinzufügen von vier Plättchen in der Einerspalte entspricht. Sollen
      sie beispielsweise eine Zahl um zwölf erhöhen, müssen sie erkennen, dass
      dies bedeutet, dass dazu ein Plättchen in der Zehnerspalte und zwei in der
      Einerspalte hinzugelegt werden. Selbstverständlich könnten sie auch zwölf
      Plättchen in die Einerspalte legen und zehn von diesen dann zu einem
      Plättchen in der Zehnerspalte bündeln. Durch das Hinzufügen der zwölf
      Plättchen in gebündelter Form würden sie sich diesen Arbeitsschritt jedoch
      sparen und könnten mit einer deutlich übersichtlicheren Anzahl an
      Plättchen hantieren. Dies zu erkennen ist eine Herausforderung für die
      Kinder, welche aber zugleich einen Grundstein für eine verstehensbasierte
      Durchführung der Addition und Subtraktion von Zahlen darstellt.
    </p>

    <p>
      Die Videos zum Bündeln in anderen Stellenwertsystemen zeigen, dass das für
      uns selbstverständliche Prinzip des Bündelns den Kindern ein hohes Maß an
      Konzentration und Aufmerksamkeit abverlangt. Wo für uns selbstverständlich
      ist, wann gebündelt werden muss und welche Auswirkungen das hat, müssen
      Kinder immer wieder bewusst und aktiv überlegen und dabei viele Dinge
      beachten. Dieses Nachvollziegen von Handlungen in anderen und ungewohnten
      Stellenwertsystemen kann verdeutlichen, wie anspruchsvoll das Erlernen der
      Systematik des Stellenwertsystems und des Umgangs mit Zahlen in eben
      diesem für Kinder sein muss.
    </p>

    <p>
      Zusammenfassend lassen sich folgende Kompetenzen für Schülerinnen und
      Schüler festhalten:
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            ungebündelte Materialsätze so lange bündeln, bis keine weitere
            Bündelung mehr möglich ist.
          </li>
          <li>
            gebündelte Materialsätze so lange entbündeln, bis keine weitere
            Entbündelung mehr möglich ist.
          </li>
          <li>
            Bündelungen und Entbündelungen mit verschiedenen didaktischen
            Materialien (bspw. Zehnersystemmaterial oder Plättchen in der
            Stellenwerttafel) verstehend vornehmen.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <h3>Stellenwert und Zahlenwert</h3>
    <p>
      Neben dem Prinzip der fortgesetzten Bündelung kennzeichnen
      Stellenwertsysteme das Prinzip des Stellenwerts und das Prinzip des
      Zahlenwerts. Nur durch das Zusammenspiel dieser beiden Prinzipien kommen
      wir tatsächlich mit den zehn Ziffern von 0 bis 9 für die Darstellung einer
      beliebig großen Zahl aus.
    </p>
    <h4>Was solltest Du zum Stellenwert und Zahlenwert wissen?</h4>
    <p>
      Das Prinzip des Stellenwerts meint, dass die Position der Ziffer ihren
      Stellenwert bestimmt, wobei die Bündelungseinheiten von rechts nach links
      ansteigen. Das heißt, die Stellung der Ziffer innerhalb des Zahlwortes
      gibt die Mächtigkeit der zugehörigen Bündel an, also aus wie vielen
      Elementen ein Bündel an der entsprechenden Stelle besteht (Stellenwert der
      Ziffer). Hier kommt das Prinzip des Zahlenwerts ins Spiel. Der Zahlenwert
      der Ziffer gibt die Anzahl der Bündel des jeweiligen Stellenwerts an (vgl.
      Padberg & Benz 2011, 82). Zum Verständnis dieser Konventionen ist
      einerseits die Einsicht in die Zerlegbarkeit von Zahlen
      (Teil-Teil-Ganzes-Beziehung, additive Eigenschaft von Stellenwertsystemen)
      wichtig: 23 ist dasselbe wie 20 und 3 oder 2 Z(ehner) und 3 E(iner) oder 3
      und 20.
    </p>

    <p>
      In Folge dessen kommt der Ziffer Null eine besondere Bedeutung zu, denn
      ein nicht besetzter (kleinerer) Stellenwert muss mit der Ziffer Null
      gekennzeichnet und darf nicht einfach weggelassen werden: Beispielsweise
      ist 467 ist eine ganz andere Zahl als 40607. Das bedeutet also, dass vom
      höchsten Stellenwert ausgehend, jede niedrigere Stelle besetzt sein muss,
      gegebenenfalls mit einer Null.
    </p>
    <p>
      Für uns Erwachsene ist die Nutzung dieser beiden Prinzipien ganz
      selbstverständlich. Lernen Kinder das Zahlsystem kennen, ist dies für sie
      jedoch zunächst ganz neu und fordert sie enorm.
    </p>

    <p>
      Auch hier sollst Du Dich noch einmal in die Situation der Kinder
      hineinversetzen. Hierzu kannst Du dir ein Video zum Sortieren von Zahlen
      in anderen Stellenwertsystemen als dem zur Basis 10 ansehen.
    </p>

    <video
      preload="auto"
      controls
      width="60%"
      style="margin-left: 20%"
      @play="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
      @fullscreenchange="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
      @webkitfullscreenchange="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
      @mozfullscreenchange="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
      @msfullscreenchange="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
      @ended="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
      @pause="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
      @seeking="videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')"
      @enterpictureinpicture="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
      @leavepictureinpicture="
        videoPlayerEvent($event, 'SWV-I_Zahlen_sortieren_201113.mp4')
      "
    >
      <source
        src="@/assets/swvi/SWV-I_Zahlen_sortieren_201113.mp4"
        type="video/mp4"
      />
    </video>

    <p>
      Das Sortieren von Zahlen entsprechend ihrer Größe ist für uns im
      Zehnersystem eine Routinetätigkeit. Müssen wir das gleiche bei Zahlen in
      anderen Stellenwertsystemen nachvollziehen, wird uns schnell deutlich, wie
      komplex das Prinzip des Stellenwerts und das Prinzip des Zahlenwerts sowie
      ihr Zusammenspiel sind. Die Prinzipien des Stellenwerts und Zahlwerts zu
      verstehen, zu verinnerlichen und anzuwenden, stellt Kinder vor eine große
      Herausforderung. Ziel des Unterrichts ist es, dass die Kinder die
      folgenden Kompetenzen erwerben:
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            erläutern, dass die Position einer Ziffer ihren Stellenwert
            bestimmt.
          </li>
          <li>
            das Ansteigen der Bündelungseinheiten (von rechts nach links)
            erklären.
          </li>
          <li>
            erläutern, dass der Wert einer Ziffer an einer bestimmten Position
            die Anzahl der entsprechenden Bündel des zugehörigen Stellenwerts
            angibt.
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <h3>Darstellungswechsel</h3>
    <p>
      Als weiteres zentrales Merkmal des Stellenwertverständnisses gilt die
      Fähigkeit, zwischen den verschiedenen Darstellungen flexibel wechseln zu
      können (Fromme, 2017). Zu den Darstellungen zählen die symbolische
      Mathesprache, Sprache, Handlungen am Material und Bilder.
    </p>

    <p>
      <strong>Mathesprache:</strong> Unter Mathesprache wird die
      Verschriftlichung einer Zahl verstanden – die sogenannten Zahlzeichen. Am
      häufigsten Verwendung findet die sogenannte Normalform (23). Neben dieser
      ‚Normalform‘ einer Zahl lassen sich weitere Schreibweisen unterscheiden,
      wie z. B. ‚2Z 3E‘ (Schreibweise mit Zehnern und Einern) oder 20 + 3
      (Additive, stellengerechte Zerlegung der Zahl).
    </p>

    <p>
      <strong>Sprache:</strong> Im Gegensatz zur Notation von Zahlen, die
      regelgeleitet unter Benutzung des Stellenwertsystems und der arabischen
      Ziffern erfolgt, ist die Zahlwortbildung im deutschen Sprachraum von
      Unregelmäßigkeiten betroffen und unterscheidet sich z. T. erheblich von
      der Zahlwortbildung in anderen Ländern bzw. Sprachräumen. Bezogen auf
      zweistellige Zahlwörter ist das deutsche Zahlwortsystem beispielsweise
      durch Inkonsistenzen zwischen dem gesprochenen Zahlwort und der Abfolge
      der Ziffern in der geschriebenen Zahl (23 - twenty-three –
      drei-und-zwanzig) gekennzeichnet.
    </p>
    <p>
      <strong>Material:</strong> Zur Darstellung größerer Zahlen greift man in
      der Regel nicht mehr auf Alltagsmaterialien, sondern auf didaktische
      Materialien zurück, welche die Strukturen unseres Dezimalsystems
      berücksichtigen. Dazu zählen zum Beispiel das Hunderterfeld, der
      Rechenrahmen oder das sog. Dienes-Material auch Zehnersystemblöcken
      genannt (Würfel, Stangen, Platten).
    </p>
    <p>
      <strong>Bilder:</strong> Auch hier gilt, dass zur Veranschaulichung
      sinnvollerweise bildliche Darstellungen genommen werden, die die Fünfer-
      bzw. die Zehnerstruktur und später die Hunderter- und die
      Tausenderstruktur zum Ausdruck bringen, wie zum Beispiel die Quadrat-,
      Strich-, Punktdarstellungen oder der Zahlenstrahl.
    </p>

    <div style="display: flex;">
      <div style="float: left">
        Mit Darstellungswechseln sind nun nicht nur die Übersetzungen zwischen
        den verschiedenen Darstellungsformen gemeint wie z. B. die Umwandlung
        eines Zahlzeichens in ein Zahlwort. Übersetzungen können darüber hinaus
        auch innerhalb einer Zahlrepräsentation stattfinden, d. h.
        beispielsweise von einer Zahldarstellung in eine andere, z. B. 32 = 30 +
        2 = 3Z + 2E oder von einer bildlichen Darstellung in eine andere.
      </div>
      <div style="width: 300px; float: right;">
        <AppExpandableImage
          contain
          :src="require('@/assets/swve/swve_darstellungswechsel.png')"
          parentId="swvi"
          elementId="swve_darstellungswechsel.png"
        >
          ></AppExpandableImage
        >
      </div>
    </div>
    <p>
      Die Lernenden müssen flexibel und verständig zwischen verschiedenen
      Zahldarstellungen hin- und herübersetzen können. Auch das Lesen, Schreiben
      und Sprechen von Zahlwörtern kann Lernhürden bereithalten (z. B. elf statt
      zehn-eins oder eins-zehn; vier-und-dreißig und nicht dreißig-und-vier,
      aber siebenhundert-dreißig, nicht dreißig-siebenhundert, etc.), die es
      gilt zu thematisieren.
    </p>

    <p>
      Ein tragfähiges Stellenwertverständnis zeichnet sich insbesondere dadurch
      aus, dass ein Kind weiß und erklären kann, wie die einzelnen Ziffern des
      Zahlzeichens (z.B. 426), die Bestandteile des Zahlwortes (z.B.
      vierhundert-sechs-und-zwanzig) und die Anzahlen der einzelnen
      Bündelungseinheiten (4 H, 2 Z, 6 E) zusammenhängen. Dazu kann die
      Durchführung von Darstellungswechseln beitragen. Die Entwicklung des
      Stellenwertverständnisses wird jedoch nicht allein durch die Anforderung
      Material in Zahlzeichen oder Zahlzeichen in Zahlworte zu übersetzen
      gefördert. Von ganz zentraler Bedeutung ist stets auch der Austausch über
      den Darstellungswechsel: Warum passt das? Was ist gleich, was ist
      verschieden? etc. (Kuhnke, 2012), weil dies zum Ausbau von Verständnis
      durch Herausarbeiten von Merkmalen beiträgt.
    </p>

    <!-- Tabelle GRUPPEN 10er-System
https://owncloud.idial.institute/apps/files/?dir=/faledia.all.project/Seiten_wip/SWVI/1%20Hintergrund/Darstellungswechsel/10erSystem&fileid=2162871
ENDE Tabelle GRUPPEN 10er-System

Tabelle GRUPPEN 5er-System
 Die Cloud will mich gerade echt fertig machen. Ich musste noch eine Änderung vornehmen und es klappt einfach nicht. Ich kann es auch nicht neu hochladen, dann bekomme ich beim Öffnen eine Fehlermeldung. Ich sende dir das passende Dokument per Mail.
ENDE Tabelle Gruppen 5er-System -->

    <p>
      In der nachfolgenden Tabelle siehst Du verschiedene
      Darstellungsmöglichkeiten für unterschiedlichen Zahlen im 10er-System.
    </p>
    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-center">
              <strong>223</strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/223_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/223_3.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/223_3.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/223_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/223_4.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/223_4.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/223_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/223_2.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/223_2.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/223_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/223_1.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/223_1.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>32</strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/32_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/32_2.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/32_2.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/32_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/32_3.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/32_3.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/32_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/32_4.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/32_4.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/32_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/32_1.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/32_1.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>203</strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/203_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/203_1.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/203_1.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/203_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/203_2.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/203_2.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/203_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/203_3.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/203_3.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/203_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/203_4.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/203_4.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>2030</strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/2030_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/2030_1.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/2030_1.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/2030_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/2030_2.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/2030_2.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/2030_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/2030_4.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/2030_4.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_10er/2030_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_10er/2030_3.jpg"
                />
              </div>
              <!-- <v-img
                contain
                width="180px"
                :src="require('@/assets/swve/SDWH2_10er/2030_3.jpg')"
                class="imageTableImages"
              ></v-img> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      In der nachfolgenden Tabelle siehst Du unterschiedliche
      Darstellungsmöglichkeiten für verschiedene Zahlen im 5er-System. Anhand
      der Zahl (302)₅ wird Dir erklärt, warum die einzelnen Darstellungen zu
      genau dieser Zahl passen. Für die anderen Zahlen in der Tabelle gibt es
      keine auditive Erläuterung, Du kannst Dir aber ansehen, welche
      Darstellungen zu der jeweiligen Zahl passen.
    </p>
    <v-simple-table class="imageTable">
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-center">
              <strong>(302)<sub>5</sub></strong>
            </td>
            <td>
              <vuetify-audio
                ref="audioPlayer1"
                :file="
                  require('@/assets/swvi/SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3')
                "
                color="primary"
                flat
                :ended="
                  () => audioEnded('SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3')
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'SDWH2_5er/SWV-I_DW_1_Stellenwerttafel.mp3',
                      $refs.audioPlayer1
                    )
                "
                class="audioPlayer"
              ></vuetify-audio>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/302_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/302_3.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <vuetify-audio
                ref="audioPlayer2"
                :file="
                  require('@/assets/swvi/SDWH2_5er/SWV-I_DW_2_Punktebild.mp3')
                "
                color="primary"
                flat
                :ended="() => audioEnded('SDWH2_5er/SWV-I_DW_2_Punktebild.mp3')"
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'SDWH2_5er/SWV-I_DW_2_Punktebild.mp3',
                      $refs.audioPlayer2
                    )
                "
                class="audioPlayer"
              ></vuetify-audio>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/302_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/302_1.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <vuetify-audio
                ref="audioPlayer3"
                :file="
                  require('@/assets/swvi/SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3')
                "
                color="primary"
                flat
                :ended="
                  () =>
                    audioEnded('SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3')
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'SDWH2_5er/SWV-I_DW_3_Oehlsche_Schreibweise.mp3',
                      $refs.audioPlayer3
                    )
                "
                class="audioPlayer"
              ></vuetify-audio>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/302_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/302_2.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <vuetify-audio
                ref="audioPlayer4"
                :file="
                  require('@/assets/swvi/SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3')
                "
                color="primary"
                flat
                :ended="
                  () => audioEnded('SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3')
                "
                @click.native="
                  () =>
                    audioPlayerClicked(
                      $event,
                      'SDWH2_5er/SWV-I_DW_4_Dienes-Material.mp3',
                      $refs.audioPlayer4
                    )
                "
                class="audioPlayer"
              ></vuetify-audio>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/302_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/302_4.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/302_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>(320)<sub>5</sub></strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/320_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/320_3.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/320_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/320_1.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/320_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/320_2.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/320_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/320_4.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/320_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>(322)<sub>5</sub></strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/322_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/322_1.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/322_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/322_2.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/322_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/322_3.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/322_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/322_4.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/322_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>(1233)<sub>5</sub></strong>
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/1233_2.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/1233_2.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_2.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/1233_4.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/1233_4.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_4.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/1233_3.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/1233_3.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_3.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
            <td>
              <div class="imageTableImages tableImageContainer">
                <AppExpandableImage
                  :src="require('@/assets/swve/SDWH2_5er/1233_1.jpg')"
                  contain
                  parentId="SWVI"
                  elementId="SDWH2_5er/1233_1.jpg"
                />
              </div>
              <!-- <v-img
              contain
              width="180px"
              :src="require('@/assets/swve/SDWH2_5er/1233_1.jpg')"
              class="imageTableImages"
            ></v-img> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p>
      Darstellungen von Zahlen können sehr vielfältig sein und enthalten diverse
      Informationen. Für die Kinder stellen alle diese Darstellungen von Zahlen
      zunächst Lernstoff dar. Sie müssen lernen die Darstellungen auf allen vier
      Darstellungsebenen zu deuten, sie zueinander in Beziehung zu setzen und
      dabei die bedeutungsrelevanten Merkmale zu identifizieren.
    </p>

    <v-card outlined raised>
      <v-card-title
        >Was sollen die Schülerinnen und Schüler lernen?</v-card-title
      >
      <v-card-text>
        Die Kinder können ...
        <ul>
          <li>
            Mengendarstellungen (auch Bilder derer) in mathematisch-symbolische
            Darstellungen übersetzen und umgekehrt
          </li>
          <li>
            Mengendarstellungen (auch Bilder derer) in verbal-symbolische
            Darstellungen übersetzen und umgekehrt
          </li>
          <li>
            mathematisch-symbolische Darstellungen in verbal-symbolische
            Darstellungen übersetzen und umgekehrt
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />

    <v-row justify="end">
      <v-btn text color="primary" @click="navigateToDiagnose">
        Weiter mit: Diagnose
        <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </v-row>

    <br/>
    <br/>
    <br/>
    <h4 id="literaturverzeichnis">Literaturverzeichnis</h4>
      <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>
              Carpenter, T., Franke, M., Jacobs, V., Fennema, E., & Empson, S. (1998). <i>A Longitudinal Study of Invention and Understanding in Children's Multidigit Addition and Subtraction.</i> In Journal for Research in Mathematics Education, Vol 29 Issue 1 (S. 3-20). 
            </td>
          </tr>
          <tr>
            <td>
              Fromme, M. (2017). <i>Stellenwertverständnis im Zahlenraum bis 100: Theoretische und empirische Analysen.</i> Wiesbaden: Springer Spektrum.
            </td>
          </tr>
          <tr>
            <td>
              Krauthausen, G., & Scherer, P. (2007). <i>Einführung in die Mathematikdidaktik.</i> München: Elsevier.
            </td>
          </tr>
          <tr>
            <td>
              Kuhnke, K. (2012). <i>Vorgehensweisen von Grundschulkindern beim Darstellungswechsel – Eine Untersuchung am Beispiel der Multiplikation im 2. Schuljahr.</i> Wiesbaden: Springer.
            </td>
          </tr>
          <tr>
            <td>
              Möller, K., Pixner, S., Zuber, J., Kaufmann, L., & Nürk, H.-C. (2011). <i>Early place-value understanding as a precursor for later arithmetic performance—A longitudinal study on numerical development.</i> In Research in Developmental Disabilities, Vol 32 Issue 5 (S. 1837-1851). 
            </td>
          </tr>
          <tr>
            <td>
              Moser Opitz, E. (2007). <i>Rechenschwäche – Dyskalkulie. Theoretische Klärungen und empirische Studien an betroffenen Schülerinnen und Schülern.</i> Bern: Haupt.
            </td>
          </tr>
          <tr>
            <td>
              Moser Opitz, E., & Schmassmann M. (2007). <i>Grundoperationen.</i> In Heimlich, U., Wember, F. (Hrsg.), <i>Didaktik des Unterrichts im Förderschwerpunkt Lernen. Ein Handbuch für Studium und Praxis</i> (S. 266-279). Stuttgart: Kohlhammer.
            </td>
          </tr>
          <tr>
            <td>
              Padberg, F., & Benz, C. (2011). <i>Didaktik der Arithmetik. Für Lehrerausbildung und Lehrerfortbildung.</i> 4. Aufl. Heidelberg: Spektrum.
            </td>
          </tr>
          <tr>
            <td>
              Schipper, W. (2009). <i>Handbuch für den Mathematikunterricht an Grundschulen.</i> Hannover: Schroedel.
            </td>
          </tr>
          <tr>
            <td>
              Schulz, A. (2015). <i>Von der Handlung in den Kopf - Übungsformen zur Verinnerlichung von Handlungen.</i> Fördermagazin Grundschule, 4, (S. 15-21).
            </td>
          </tr>
          <tr>
            <td>
              Thompson, I., & Bramald, R. (2002). <i>An investigation of the relationship between young children’s understanding of the concept of place value and their competence at mental addition.</i> (Report for the Nuffield Foundation). University of Newcastle upon Tyne.
            </td>
          </tr>
          <tr>
            <td>
              Wartha, S., & Schulz, A. (2014). <i>Rechenproblemen vorbeugen.</i> Berlin: Cornelsen.
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import AppHelper from "@/common/AppHelper";
import AppExpandableImage from "@/common/AppExpandableImage";
export default {
  beforeCreate: function() {
    AppHelper.isUserAllowedRedirectIfNot("swvi");
  },
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    AppExpandableImage,
  },
  data() {
    return {};
  },
  methods: {
    audioEnded: function(id) {
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:33; Audio komplett abgespielt;",
        id
      );
    },
    audioPlayerClicked: function(event, id, ref) {
      if (
        ref.firstPlay &&
        !event.srcElement.classList.contains("v-card__text")
      ) {
        // other than in LernbausteinStempeln a click on the surrounding vcard can trigger an event
        // the first playback can cause an unwated pause event, so we have to check it this way
        AppHelper.trackMatomoEvent(
          this,
          "SWVI",
          "id:32; Audio first play currentTime: " +
            ref.currentTime +
            " duration: " +
            ref.duration +
            ";",
          id
        );
      } else {
        if (event.srcElement.classList.contains("mdi-stop")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:34; Audio stop currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-play")) {
          // der button hat noch das andere logo, es handelt sich aber um pause
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:35; Audio pause currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-pause")) {
          // der button hat noch das andere logo, es handelt sich aber um play
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:36; Audio play currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-mute")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:37; Audio mute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        } else if (event.srcElement.classList.contains("mdi-volume-high")) {
          AppHelper.trackMatomoEvent(
            this,
            "SWVI",
            "id:38; Audio unmute currentTime: " +
              ref.currentTime +
              " duration: " +
              ref.duration +
              ";",
            id
          );
        }
      }
    },
    videoPlayerEvent: function(event, id) {
      let local_id = 0;
      if (event.type == "play") {
        local_id = 40;
      } else if (event.type == "pause") {
        local_id = 41;
      } else if (event.type == "ended") {
        local_id = 42;
      } else if (event.type == "seeking") {
        local_id = 43;
      } else if (
        event.type == "fullscreenchange" ||
        event.type == "webkitfullscreenchange" ||
        event.type == "mozfullscreenchange" ||
        event.type == "msfullscreenchange"
      ) {
        local_id = 44;
      } else if (event.type == "enterpictureinpicture") {
        local_id = 45;
      } else if (event.type == "leavepictureinpicture") {
        local_id = 46;
      }
      AppHelper.trackMatomoEvent(
        this,
        "SWVI",
        "id:" +
          local_id +
          "; Video " +
          event.type +
          " currentTime: " +
          event.currentTarget.currentTime +
          " duration: " +
          event.currentTarget.duration +
          ";",
        id
      );
    },
    navigateToDiagnose: function() {
      this.$router.push({ name: "swvi_diagnose" });
    },
  },
};
</script>

<style scoped>
.imageTableImages {
  margin-top: 10px;
  margin-bottom: 10px;
}
.imageTable {
  margin-bottom: 20px;
}

.tableImageContainer {
  width: 179px;
}

.biggerTableImageContainer {
  width: 390px;
}

.audioPlayer {
  width: 179px;
}

.audioPlayerBigger {
  width: 200px;
  margin: auto;
}
</style>
